<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import LoaderProcess from "../../../components/widgets/loaderProcess.vue";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import { Api } from "../../../helpers";
import VideosCardVue from "../../../components/widgets/VideosCard.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    LoaderProcess,
    VideosCardVue,
  },

  data() {
    return {
      isAuthError: false,
      authError: null,
      nbreElement: null,
      pageSize: 30, // Nombre d'éléments à afficher sur chaque page
      currentPage: 0, // La page actuelle
      videoRef: "",
      startDate: "",
      endDate: "",
      titre: "",
      progress: 0,
      privateId: "",
      programmeId: "",
      searchQuery: "",
      categories: [],
      categorieList:[],
      categorie: [],
      process: false,
      modalOk: false,
      importModalRef: false,
      addChoice: false,
      video: {
        image: "",
        description: "",
        annee: "",
        duree: "",
        titre: "",
        publicationDate: "",
        shortDescription: "",
      },
      newVideoSave: {
        titre: "",
        description: "",
      },
      channelId: "",
      tags: [],
      tagList: [],
      channels: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      spinner: false,
      title: "Liste de Vidéos",
      items: [
        {
          text: "Contenu",
          // href: "/projects/categories",
        },
        {
          text: "Vidéos",
          active: true,
        },
      ],

      activeFilter: "all",
      filteredVideos: [],
    };
  },
  methods: {
    filterVideos(filter) {
      if (filter === "all") {
        this.filteredVideos = this.videos;
      } else if (filter === "published") {
        this.filteredVideos = this.videos.filter(
          (video) => video.isPublished === true
        );
      } else if (filter === "unpublished") {
        this.filteredVideos = this.videos.filter(
          (video) => video.isPublished === false
        );
      }
      this.filter = filter; // Mettre à jour l'état du filtre actif

      // Mettre à jour le filtre actif
      this.activeFilter = filter;
    },

    // Add a tag to the list of tags
    addTags(event) {
      // Prevent the default form submission behavior
      event.preventDefault();

      // Get the value of the input and trim any whitespace
      const val = event.target.value.trim();

      // If the input has a value and the tag is not already in the list
      if (val.length > 0 && !this.tags.includes(val)) {
        // Add the tag to the list
        this.tags.push(val);
        // Clear the input field
        event.target.value = "";
      }
    },

    // Remove a tag from the list of tags
    removeTags(index) {
      // Remove the tag at the specified index
      this.tags.splice(index, 1);
    },

    // Remove the last tag if the input field is empty
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        // Remove the last tag from the list
        this.removeTags(this.tags.length - 1);
      }
    },

    // Show a modal dialog
    showModal() {
      this.modalOk = true;
    },
    showImportModalRef() {
      this.importModalRef = true;
      this.addChoice = false;
    },

    showAddChoice() {
      this.addChoice = true;
    },

    // Show a confirmation dialog and delete an event if confirmed
    processus() {
      Swal.fire({
        // Show a warning message
        title: "Etes vous sùre?",
        // Set the icon to a warning symbol
        icon: "warning",
        // Show a cancel button and a confirm button
        showCancelButton: true,
        // Set the text of the confirm button to green
        confirmButtonColor: "#34c38f",
        // Set the text of the cancel button to red
        cancelButtonColor: "#f46a6a",
        // Set the text of the cancel button
        cancelButtonText: "Annuler",
        // Set the text of the confirm button
        confirmButtonText: "Supprimer",
      }).then((result) => {
        // If the user confirms the deletion
        if (result.value) {
          // Delete the event
          this.deleteProgramme();
          // Show a success message
          Swal.fire("Deleted!", "Event has been deleted.", "success");
          // Reload the page
          location.reload();
        }
      });
    },

    // This function is called when a file is selected from the input field

    videoSelect(event) {
      this.selectedFile = event.target.files[0]; // Get the selected image or video
      this.video.titre = this.selectedFile.name;
      const file = event.target.files[0];
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.addEventListener("loadedmetadata", () => {
        this.video.duree = this.formatDuration(video.duration);
      });
    },

    formatDuration(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = Math.floor(duration % 60);

      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");

      return `${hoursString}:${minutesString}:${secondsString}`;
    },
    // This function is called when an image or video is selected from the input field
    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
    },
    // This function is called when the user clicks the upload button
    uploadVideo() {
      if (
        // !this.selectedFile ||
        // !this.imgSelect ||
        // !this.imgPaysage ||
        !this.channelId ||
        !this.video.duree ||
        !this.video.titre ||
        !this.video.shortDescription||
        !this.video.publicationDate||
        // !this.video.annee ||
        !this.categorie ||
        !this.tags
      ) {
        this.isAuthError = true;
        this.authError = "Veuillez renseigner tous les champs obligatoires";
      } else {
        this.titre = "Chargement de vidéo";
        this.progress = true;
        this.progressValue = 0;

        this.process = true;
        this.modalOk = false;
        const onUploadProgress = (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          this.progress = Math.min(progress, 98); // Limite la valeur maximale à 98
        };

        // if (!this.programmeId) {
        Api.post(
          `/streamvod/rest/videos/create-content/${this.videoRef}?categorie=${this.categorie}&tags=${this.tags}&channelId=${this.channelId}`,
          {
            // file: this.selectedFile,
            // image: this.imgSelect,
            // imagepaysage: this.imgPaysage,
            // video:{
            description: this.video.description,
            duration: this.video.duree,
            title: this.video.titre,
            shortDescription: this.video.shortDescription,
            dateSortie: this.video.publicationDate,
            // years: this.video.annee,
            // }
          },
          onUploadProgress
        )
          .then(() => {
            Swal.fire("Success!", "Vidéo ajoutée", "success");
            this.progress = false;
            this.process = false;
          })
          .catch((error) => {
            this.process = false;
            this.progress = 0;
            Erreur.gestionErreur(error.message);
          });
      }
    },

    // This function is called to get the channel data
    getChannel() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      // Call the Api.get function to get the channel data
      Api.get("/streamvod/api/channel/channel-all", onUploadProgress)
        .then((response) => {
          // If the channel data is received successfully
          this.channels = response.data.content; // Store the channel data in the channels variable
        })
        .catch((error) => {
          // If there's an error
          this.process = false; // Set the process variable to false
          Erreur.gestionErreur(error.message); // Call the error handling function
        });
    },

    goToPage(page) {
      // Vérifier si la page demandée est valide
      if (page >= 0 && page <= this.pageCount) {
        this.currentPage = page;
      }
    },

    importVideoByRef() {
      if (!this.videoRef) {
        this.isAuthError = true;
        this.authError = "Veuillez renseigner tous les champs obligatoires";
      } else {
        this.titre = "Importation de la vidéo";

        this.process = true;
        this.importModalRef = false;

        // if (!this.programmeId) {
        Api.get(`/streamvod/api/videos/check-video/${this.videoRef}`)
          .then((response) => {
            this.progress = false;
            this.process = false;

            this.video.titre = response.data.title;
            this.video.description = response.data.description;
            this.video.duree = response.data.duration;
            this.privateId = response.data.privateId;
            // description: this.video.description,
            // title: this.video.titre,
            this.modalOk = true;
          })
          .catch((error) => {
            this.process = false;
            this.progress = 0;
            Erreur.gestionErreur(error.message);
          });
      }
    },
  },
  mounted() {
    this.spinner = true;
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    };

    Api.get("/streamvod/rest/programme/all")
      .then((response) => {
        this.programmes = response.data.content;
      })
      .catch((error) => {
        this.process = false;
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvod/rest/videos/all", onUploadProgress)
      .then((res) => {
        this.spinner = false;
        this.videos = res.data.content;
        this.filterVideos("all");
      })
      .catch((error) => {
        this.spinner = false;
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvod/rest/categories/all", onUploadProgress)
      .then((res) => {
        this.categories = res.data.content;
        this.categorieList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/streamvod/rest/tags/all", onUploadProgress)
      .then((res) => {
        this.tagList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    this.getChannel();
  },
  watch: {
    startDate(newVal) {
      this.endDate = newVal; // Mettre la valeur minimale à la nouvelle valeur de startDate
    },
  },
  computed: {
    filteredVideosList() {
      if (!this.startDate || !this.endDate) {
        if (this.searchQuery) {
          return this.videos.slice().filter((video) => {
            const title = video.title.toLowerCase();
            const searchQuery = this.searchQuery.toLowerCase();

            return title.includes(searchQuery);
          });
        }
        return this.videos.slice().reverse();
      }

      const start = new Date(this.startDate).getTime();
      const end = new Date(this.endDate).getTime();

      return this.videos.slice().filter((video) => {
        const createdAt = new Date(video.created).getTime();
        const title = video.title.toLowerCase();
        const searchQuery = this.searchQuery.toLowerCase();

        return (
          createdAt >= start && createdAt <= end && title.includes(searchQuery)
        );
      });
    },
    pages() {
      // Générer une liste de nombres de pages à afficher dans la pagination
      let pages = [];
      for (let i = 0; i <= this.pageCount - 1; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageCount() {
      return Math.ceil(this.filteredVideosList.length / this.pageSize);
    },
    paginatedVideos() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredVideosList.slice(start, end);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <loader-process :visible="process" :progress="progress" :title="titre" />

    <div class="row mb-3">
      <div class="col-lg-5">
        <br /><br />
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher une vidéo"
            style="border-radius: 8px"
            v-model="searchQuery"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de début</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="date"
                  class="form-control"
                  v-model="startDate"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de fin</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="date"
                  class="form-control"
                  v-model="endDate"
                  :min="startDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-1">
        <br /><br />
        <!-- <button
          type="submit"
          style="border-radius: 8px"
          class="btn c2play-primary"
          @click="showModal"
        >
          {{ $t("addButton") }}
        </button> -->
        <button
          type="submit"
          style="border-radius: 8px"
          class="btn c2play-primary"
          @click="showAddChoice"
        >
          Ajouter
        </button>
      </div>
    </div>
    <div class="row">
      <div style="display: flex; align-items: center; justify-content: center">
        <button
          @click="filterArticles('all')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'all' }"
        >
          Tous
        </button>
        <button
          @click="filterArticles('published')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'published' }"
        >
          Publiés
        </button>
        <button
          @click="filterArticles('unpublished')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'unpublished' }"
        >
          Non publiés
        </button>
      </div>
    </div>

    <br />
    <!-- <b-tabs pills justified content-class="p-3 text-muted">
      <b-tab active class="border-0">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="mdi mdi-format-list-bulleted"></i>
          </span>
          <span class="d-none d-sm-inline-block">Liste</span>
        </template> -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 70px">Image</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Durée</th>
                    <th scope="col">Likes</th>
                    <th scope="col">Commentaires</th>
                    <th scope="col">Vues</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="categorieList.length > 0">
                  <VideosCardVue
                    v-for="video in paginatedVideos"
                    :key="video.id"
                    
                    :affichage="'Liste'"
                    :video-props="video"
                    :chaines="channels"
                    :categ="categorieList"
                    :tag="tagList"
                    :programme="programmes"
                  ></VideosCardVue>
                </tbody>
              </table>
              <div
                style="text-align: center; font-size: 3em"
                v-if="spinner == true"
              >
                <b-spinner variant="success" role="status"></b-spinner>
              </div>

              <nav
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                v-if="pages.length > 1"
              >
                <ul class="pagination">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 0 }"
                  >
                    <button
                      class="page-link"
                      style="
                        margin: 5px;
                        font-size: 15px;
                        border-radius: 20px 0 0 20px;
                      "
                      @click="goToPage(currentPage - 1)"
                    >
                      Previous
                    </button>
                  </li>
                  <li v-for="page in pages" :key="page" class="page-item">
                    <button
                      class="page-link"
                      :class="{ active2play: currentPage === page }"
                      style="
                        height: 45px;
                        width: 45px;
                        border-radius: 50%;
                        margin: 5px;
                      "
                      @click="goToPage(page)"
                    >
                      {{ page + 1 }}
                    </button>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === pageCount - 1 }"
                  >
                    <button
                      class="page-link"
                      style="
                        margin: 5px;
                        font-size: 15px;
                        border-radius: 0 20px 20px 0;
                      "
                      @click="goToPage(currentPage + 1)"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--         
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="mdi mdi-view-grid-outline"></i>
          </span>
          <span class="d-none d-sm-inline-block">Carte</span>
        </template>

        <div>
          <div class="row">
            <VideosCardVue
              v-for="video in paginatedVideos.slice().reverse()"
              :key="video.id"
              :affichage="'Grille'"
              :image="video.image.url"
              :imagePaysage="video.imagepaysage.url"
              :title="video.title"
              :vues="video.nbVues"
              :likes="video.likedUsers.length"
              :time="video.duration"
              :years="video.years"
              :id="video.id"
              :commentaires="video.comments.length"
              :chaines="channels"
              :categ="categories"
              :tag="tagList"
              :description="video.description"
              :channelId="video.channel"
              :categorie="video.categorie"
              :initialTags="video.tags"
              :programme-id="video.programme"
              :programme="programmes"
            ></VideosCardVue>
          </div>
          <nav
            style="
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            v-if="pages.length > 1"
          >
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 0 }">
                <button
                  class="page-link"
                  style="
                    margin: 5px;
                    font-size: 15px;
                    border-radius: 20px 0 0 20px;
                  "
                  @click="goToPage(currentPage - 1)"
                >
                  Previous
                </button>
              </li>
              <li v-for="page in pages" :key="page" class="page-item">
                <button
                  class="page-link"
                  :class="{ active2play: currentPage === page }"
                  style="
                    height: 45px;
                    width: 45px;
                    border-radius: 50%;
                    margin: 5px;
                  "
                  @click="goToPage(page)"
                >
                  {{ page + 1 }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === pageCount - 1 }"
              >
                <button
                  class="page-link"
                  style="
                    margin: 5px;
                    font-size: 15px;
                    border-radius: 0 20px 20px 0;
                  "
                  @click="goToPage(currentPage + 1)"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </b-tab>
    </b-tabs> -->

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.videos.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="col-lg-6">
              <br />
              <iframe
                frameborder="0"
                style="width: 100%;"
                height="370"
                :src="
                  'https://geo.dailymotion.com/player/xpnus.html?video=' +
                  privateId
                "
                allowfullscreen
                allow="autoplay; fullscreen; picture-in-picture; web-share"
              >
              </iframe>
            </div>
            <div class="col-lg-6">
              <div class="form-group row mb-2">
                <div class="col-lg-12">
                  <label for="projectname" class="col-form-label">{{
                    $t("pages.videos.formulaire.titre.label")
                  }}</label
                  ><span style="color: red; font-size: 1.2em">*</span>
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="video.titre"
                    :placeholder="
                      $t('pages.videos.formulaire.titre.placeholder')
                    "
                  />
                </div>

                <div class="col-lg-12">
                  <label class="col-form-label">{{
                    $t("pages.videos.formulaire.channel.label")
                  }}</label
                  ><span style="color: red; font-size: 1.2em">*</span>
                  <div class="col-md-12">
                    <select class="form-control" v-model="channelId">
                      <option
                        :value="channel.id"
                        v-for="channel in channels"
                        :key="channel.id"
                      >
                        {{ channel.channel_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-lg-12">
                  <label class="col-form-label">{{
                    $t("pages.videos.formulaire.categ.label")
                  }}</label
                  ><span style="color: red; font-size: 1.2em">*</span>
                  <div class="col-md-12">
                    <select class="form-control" v-model="categorie">
                      <option value="">
                        {{ $t("pages.videos.formulaire.categ.placeholder") }}
                      </option>
                      <option
                        v-for="categ in categories"
                        :key="categ.id"
                        :value="categ.name"
                      >
                        {{ categ.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-12">
                  <label class="col-form-label">{{
                    $t("pages.videos.formulaire.tags.label")
                  }}</label>
                  <span style="color: red; font-size: 1.2em">*</span>
                  <div class="form-control">
                    <div
                      v-for="(tag, index) in tags"
                      :key="index"
                      class="tags-input_tag c2play-primary"
                    >
                      <span @click="removeTags(index)"
                        ><i class="mdi mdi-close-thick"></i
                      ></span>
                      {{ tag }}
                    </div>
                    <select
                      @keydown="addTags"
                      @keydown.delete="removeLastTag"
                      class="tags-input_text"
                      @change="addTags"
                    >
                      <option value="">
                        {{ $t("pages.videos.formulaire.tags.placeholder") }}
                      </option>
                      <option
                        aria-placeholder="okokok"
                        v-for="tagL in tagList"
                        :key="tagL.id"
                        :value="tagL.name"
                      >
                        {{ tagL.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="projectname" class="col-form-label"
                      >Date de sortie</label
                    >
                    <div class="col-lg-12">
                      <input
                        id="projectname"
                        name="projectname"
                        type="date"
                        class="form-control"
                        v-model="video.publicationDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectdesc" class="col-form-label"
                >{{
                  $t("pages.videos.formulaire.description.label")
                }}
                courte</label
              >
              <div class="col-lg-12">
                <textarea
                  id="projectdesc"
                  class="form-control"
                  rows="3"
                  v-model="video.shortDescription"
                  :placeholder="
                    $t('pages.videos.formulaire.description.placeholder')
                  "
                ></textarea>
              </div>
            </div>
            <div class="col-lg-6">
              <label for="projectdesc" class="col-form-label">{{
                $t("pages.videos.formulaire.description.label")
              }}</label>
              <div class="col-lg-12">
                <textarea
                  id="projectdesc"
                  class="form-control"
                  rows="3"
                  v-model="video.description"
                  :placeholder="
                    $t('pages.videos.formulaire.description.placeholder')
                  "
                ></textarea>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="uploadVideo"
              style="position: relative; left: 45%"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.videos.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-6">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="video.titre"
                :placeholder="$t('pages.videos.formulaire.titre.placeholder')"
              />
            </div>

            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.videos.formulaire.channel.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-md-12">
                <select class="form-control" v-model="channelId">
                  <option
                    :value="channel.id"
                    v-for="channel in channels"
                    :key="channel.id"
                  >
                    {{ channel.channel_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label class="col-form-label">{{
                $t("pages.videos.formulaire.years.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  v-model="video.annee"
                  class="form-control"
                  :placeholder="$t('pages.videos.formulaire.years.placeholder')"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectbudget" class="col-form-label">{{
                $t("pages.videos.formulaire.duree.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="text"
                  v-model="video.duree"
                  :placeholder="$t('pages.videos.formulaire.duree.placeholder')"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="form-group col-lg-4">
              <label for="projectname" class="col-form-label"
                >{{ $t("pages.videos.formulaire.files.image") }} portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelect($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectname" class="col-form-label"
                >Image paysage</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelectPaysage($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label">{{
                $t("pages.videos.formulaire.files.video")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="videoSelect($event)"
                  class="form-control"
                  accept=".MKV, .AVI, .MP4, MOV"
                />
              </div>
            </div>
          </div>

          <div class="mb-2 row">
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.videos.formulaire.categ.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-md-12">
                <select class="form-control" v-model="categorie">
                  <option value="">
                    {{ $t("pages.videos.formulaire.categ.placeholder") }}
                  </option>
                  <option
                    v-for="categ in categories"
                    :key="categ.id"
                    :value="categ.name"
                  >
                    {{ categ.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.videos.formulaire.tags.label")
              }}</label>
              <span style="color: red; font-size: 1.2em">*</span>
              <div class="form-control">
                <div
                  v-for="(tag, index) in tags"
                  :key="index"
                  class="tags-input_tag c2play-primary"
                >
                  <span @click="removeTags(index)"
                    ><i class="mdi mdi-close-thick"></i
                  ></span>
                  {{ tag }}
                </div>
                <select
                  @keydown="addTags"
                  @keydown.delete="removeLastTag"
                  class="tags-input_text"
                  @change="addTags"
                >
                  <option value="">
                    {{ $t("pages.videos.formulaire.tags.placeholder") }}
                  </option>
                  <option
                    aria-placeholder="okokok"
                    v-for="tagL in tagList"
                    :key="tagL.id"
                    :value="tagL.name"
                  >
                    {{ tagL.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.videos.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="video.description"
                :placeholder="
                  $t('pages.videos.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="uploadVideo"
              style="position: relative; left: 45%"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal> -->

    <b-modal
      v-model="importModalRef"
      id="modal-xl"
      size="m"
      title="Importer une vidéo depuis Daily Motion"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-12">
              <label for="projectname" class="col-form-label">Référence</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="videoRef"
                placeholder="Entrez la référence de la vidéo"
              />
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px; text-align: center">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="importVideoByRef"
            >
              Importer
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="addChoice"
      id="modal-xl"
      size="m"
      title="Que souhaitez vous faire ?"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div class="row">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Importer depuis DailyMotion
          </button>
        </div>
        <div class="row mt-2">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Sélectionner un fichier local
          </button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style></style>
